<template>
  <div class="not-find">
    <div class="web-empty-main">
      <div class="web-empty-images">
        <img class="web-empty-images" src="../assets/images/empty-icon.svg">
        <div class="web-empty-description">您所访问的页面不小心在太空中走丢了</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'not-find',
  }
</script>

<style lang="scss" scoped>
.not-find {
  height: 100vh;
  .web-empty-main {
    @include w-flex;
    @include align-items;
    height: 100%;
    justify-content: center;
    .web-empty-images {width: 180px}
    .web-empty-description {
      margin-top: 16px;
      text-align: center;
      color: #969799;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
